

.custom-breadcrumb {
    position: relative;
    background: #f5f5f5; 
    padding: 40px 0;
    text-align: center;
    color: #333;
    height: 700px;
}

.custom-breadcrumb-container {
    position: relative;
    z-index: 1;.custom-breadcrumb {
        position: relative;
        background: #f5f5f5; 
        padding: 80px 0; 
        text-align: center;
        color: #333;
    }
    
    .custom-breadcrumb-container {
        position: relative;
        z-index: 1;
        background: rgba(255, 255, 255, 0.5); 
        padding: 40px; 
        border-radius: 4px;
    }
    
    .custom-breadcrumb-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        transition: filter 0.3s;
    }
    
    .custom-breadcrumb:hover .custom-breadcrumb-bg {
        filter: none; 
    }
    
    .custom-breadcrumb-heading {
        font-size: 48px; 
        margin-bottom: 20px; 
        color: #333;
    }
    
    .custom-breadcrumb-description {
        font-size: 24px;
        margin-bottom: 40px;
        color: #666;
    }
    
    .custom-breadcrumb-navigation {
        display: flex;
        justify-content: center;
        margin-top: 40px; 
    }
    
    .custom-breadcrumb-list {
        list-style: none;
        padding: 0;
        display: flex;
    }
    
    .custom-breadcrumb-item {
        margin: 0 20px;
        font-size: 20px; 
        color: #666;
    }
    
    .custom-breadcrumb-item a {
        text-decoration: none;
        color: #333;
        transition: color 0.3s;
    }
    
    .custom-breadcrumb-item a:hover {
        color: #ffc107; 
    }
    
    .custom-breadcrumb-active {
        font-weight: bold;
    }
    
    /* background: rgba(255, 255, 255, 0.7);  */
    padding: 20px;
    border-radius: 4px;
}

.custom-breadcrumb-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: filter 0.3s;
}

.custom-breadcrumb:hover .custom-breadcrumb-bg {
    filter: none; 
}

.custom-breadcrumb-heading {
    font-size: 36px;
    margin-bottom: 10px;
    color: #333;
}

.custom-breadcrumb-description {
    font-size: 18px;
    margin-bottom: 20px;
    color: #666;
}

.custom-breadcrumb-navigation {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.custom-breadcrumb-list {
    list-style: none;
    padding: 0;
    display: flex;
}

.custom-breadcrumb-item {
    margin: 0 10px;
    font-size: 16px;
    color: #666;
}

.custom-breadcrumb-item a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s;
}

.custom-breadcrumb-item a:hover {
    color: #ffc107; 
}

.custom-breadcrumb-active {
    font-weight: bold;
}
