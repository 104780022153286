.hero-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    overflow: hidden;
    width: 100%;
  }
  
  .hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-content {
    position: relative;
    z-index: 1;
    max-width: 40%;
    left: -20vw;
    color: #fff;
    padding: 20px;
  }
  
  .hero-content h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .hero-button {
    background-color: #004B76;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
  }
  
  @media (max-width: 1200px) {
    .hero-content {
      max-width: 50%;
    }
  
    .hero-content h1 {
      font-size: 2em;
    }
  
    .hero-content p {
      font-size: 1em;
    }
  }
  
  @media (max-width: 768px) {
    .hero-content {
      max-width: 40%;
      padding: 10px;
    }
  
    .hero-content h1 {
      font-size: 1.5em;
    }
  
    .hero-content p {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 576px) {
    .hero-container {
      justify-content: center;
      padding: 0;
    }
  
    .hero-background {
      width: 100%;
      height: 100%;
      object-position: left;
    }
  
    .hero-content {
      max-width: 75%;
      padding: 10px;
      text-align: start;
      left: -10vw;
    }
  
    .hero-content h1 {
      font-size: 1.3em;
    }
  
    .hero-content p {
      font-size: 0.9em;
    }
  
    .hero-button {
      padding: 8px 16px;
    }
  }
  